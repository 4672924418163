<template>
    <div>
        <div class="p-d-flex p-flex-wrap p-jc-center p-mb-6">
            <custom-button
                v-for="(year, index) in years"
                :key="`btn-${index}`"
                class="p-mt-3 p-mx-3"
                width="160px"
                :text="year.toString()"
                :bg-color="currentTab === year ?  '#4878b0' : 'white'"
                :border-color="currentTab === year ? 'white' : '#4878b0'"
                @click="changeTab(year)"
            ></custom-button>
        </div>
        <div>
            <publication-row
                v-for="(item, index) in currentPub"
                :key="`pub-${index}`"
                :author="item.pubAuthor"
                :conferenceLocation="item.pubCLoc"
                :conferenceName="item.pubCName"
                :publicationId="item.pubId"
                :language="item.pubLang"
                :year="item.pubYear"
                :month="item.pubMonth"
                :title="item.pubTitle"
                :type="item.pubType"
                :url="item.url"
                class="p-my-3"
                year-color="white"
                year-bg-color="main"
                text-color="main"
                border-color="main"
            ></publication-row>
        </div>
    </div>
</template>



<script>
    import { onMounted, ref, computed } from "vue";
    import CustomButton from "@/components/common/CustomButton";
    import PublicationRow from "@/components/publication/PublicationRow";
    import { apiExecutor } from "@/api";

    export default {
        name: 'Publication',
        components: { CustomButton, PublicationRow },
        setup() {
            let publication = ref([]);
            let years = ref([]);
            let currentTab = ref('all');
            let currentPub = computed(() => {
                return currentTab.value === 'all' ?
                    publication.value :
                    publication.value.filter((item) => item.pubYear === currentTab.value)
                ;
            });

            onMounted(async () => {
                publication.value = await apiExecutor.getPublicationList();
                currentPub.value = publication.value;
                years.value = publication.value.reduce((accumulator, current) => {
                    accumulator.findIndex((val) => val === current.pubYear)=== -1 && accumulator.push(current.pubYear);
                    return accumulator;
                }, ['all']);
            });

            const changeTab = function (tab) {
                currentTab.value = tab;
            };

            return { currentTab, currentPub, years, changeTab };
        }
    }
</script>