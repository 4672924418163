<template>
    <div class="publication-row p-grid" :class="`border-${borderColor}`">
        <div class="p-col-12 p-lg-1 p-d-flex p-jc-center p-align-center" :class="`text-${yearColor} ${yearBgColor}`">{{ year }}</div>
        <h3 class="p-col-12 p-lg-10 p-px-4" :class="`text-${textColor}`">{{ citation }}</h3>
        <a class="p-col-12 p-lg-1 p-d-flex p-jc-center p-align-center" :class="`text-${textColor} border-left-${borderColor}`" target="_blank" :href="publicationUrl(publicationId)">full-text</a>
    </div>
</template>

<script>
    export default {
        name: 'PublicationRow',
        props: {
            author: String,
            conferenceLocation:String,
            conferenceName:String,
            publicationId:Number,
            language:String,
            year: Number,
            month:Number,
            title:String,
            type:Number,
            // citation: String,
            // url: String,
            yearColor: String,
            yearBgColor: String,
            textColor: String,
            borderColor: String
        },
        data(){
            return {
                url:"http://140.119.164.214:408/storage/pdf/", // absolute path should be storage/pdf/檔名.pdf
                fileType:".pdf",
            }
        },
        methods:{
            publicationUrl(pubId){
                return this.url + pubId + this.fileType;
            },
        },
        computed:{
            citation(){
                let arr = [this.author, this.year + "." + this.month, 
                this.title, this.conferenceName, this.conferenceLocation];
                return arr.join(", ");
            },

        },
    }
</script>